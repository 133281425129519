@keyframes pulse_1__1 {
	50% {
		opacity: .5;
	}
	70% {
		width: 20px;
		height: 20px;
	}
	100% {
		width: initial;
		height: initial;
		opacity: 0;
	}
}

@keyframes pulse_2__1 {
	100% {
		width: 5vw;
		height: 5vw;
		opacity: 0;
	}
}

@keyframes pulse_3__1 {
	50% {
		width: 1vw;
		height: 1vw;
	}
	100% {
		width: .5vw;
		height: .5vw;
	}
}

@keyframes levitation_1__1 {
	0% {
		transform: translatey(0)
	}
	50% {
		transform: translatey(-20px)
	}
	100% {
		transform: translatey(0)
	}
}

@keyframes preloader_2__1 {
	0% {
		top: 100%
	}
	40% {
		top: 60%
	}
	100% {
		top: 0
	}
}










[data-d~="bd-cr=1"] { background-color: #000000 }
[data-d~="bd-cr=2"] { background-color: #FFFFFF }
[data-d~="bd-cr=3"] { background-color: #171b24 }
[data-d~="bd-cr=4"] { background-color: #eb0000 }
[data-d~="bd-cr=5"] { background-color: #e8e8e8 }
[data-d~="bd-cr=6"] { background-color: #2b2f3b }
[data-d~="bd-cr=7"] { background-color: #e8e8e8 }
[data-d~="bd-cr=8"] { background-color: rgba(0, 0, 0, .85) }
[data-d~="bd-cr=9"] { background-color: transparent }
[data-d~="bd-cr=10"] { background-color: #070e2c }
[data-d~="bd-cr=11"] { background-color: #cccccc }
[data-d~="bd-cr=12"] { background-color: #464646 }
[data-d~="bd-cr=13"] { background-color: #2b2f3b }
[data-d~="bd-cr=14"] { background-color: rgba(255, 255, 255, .85) }
[data-d~="bd-cr=15"] { background-color: #f2f2f2 }
[data-d~="bd-cr=16"] { background-color: #111111 }
[data-d~="bd-cr=17"] { background-color: rgba(0, 0, 0, .5) }
[data-d~="bd-cr=18"] { background-color: #c01925 }
[data-d~="bd-cr=19"] { background-color: #c01925 }
[data-d~="bd-cr=20"] { background-color: #352985 }
[data-d~="bd-cr=21"] { background-color: #3989C9 }
[data-d~="bd-cr=22"] { background-color: #F7F7F5 }
[data-d~="bd-cr=23"] { background-color: rgba(255, 255, 255, .6) }
[data-d~="bd-cr=24"] { background-color: #efeaea }
[data-d~="bd-cr=25"] { background-color: #333333 }
[data-d~="bd-cr=26"] { background-color: #3f4045 }





[data-d~="bd-ie=1"] { background-image: url("/resources/texture_1__1.png") }

[data-d~="bd-pn=cr"] { background-position: center }

[data-d~="bd-se=cr"] { background-size: cover }

[data-d~="bd-rt=cr"] { background-repeat: no-repeat }

[data-d~="bd-at=fd"] { background-attachment: fixed }



[data-d~="wh=1"] { width: 100% }
[data-d~="wh=2"] { width: 90px }
[data-d~="wh=3"] { width: 50px }
[data-d~="wh=4"] { width: 60px }
[data-d~="wh=5"] { width: 40px }
[data-d~="wh=6"] { width: 10px }
[data-d~="wh=7"] { width: calc(100% - 80px) }
[data-d~="wh=8"] { width: 20px }
[data-d~="wh=9"] { width: 100px }
[data-d~="wh=10"] { width: 80px }
[data-d~="wh=11"] { width: calc(100% - 120px) }
[data-d~="wh=12"] { width: calc(100% - 60px) }
[data-d~="wh=13"] { width: 0 }
[data-d~="wh=14"] { width: 160px }
[data-d~="wh=15"] { width: 70px }
[data-d~="wh=16"] { width: calc(100% - 500px) }
[data-d~="wh=17"] { width: 180px }
[data-d~="wh=18"] { width: 30px }
[data-d~="wh=19"] { width: calc(100% - 90px) }
[data-d~="wh=20"] { width: 4px }
[data-d~="wh=21"] { width: 320px }
[data-d~="wh=22"] { width: 250px }
[data-d~="wh=23"] { width: 500px }
[data-d~="wh=24"] { width: 20% }
[data-d~="wh=25"] { width: 40% }
[data-d~="wh=26"] { width: 60% }
[data-d~="wh=27"] { width: 400px }
[data-d~="wh=28"] { width: 26px }
[data-d~="wh=29"] { width: 16px }
[data-d~="wh=30"] { width: 75% }
[data-d~="wh=31"] { width: .5vw }
[data-d~="wh=32"] { width: 120px }
[data-d~="wh=33"] { width: calc(40% - 10px) }
[data-d~="wh=34"] { width: 300px }
[data-d~="wh=35"] { width: 3px }
[data-d~="wh=36"] { width: 30% }
[data-d~="wh=37"] { width: 1px }

[data-d~="mn-wh=1"] { min-width: 30% }
[data-d~="mn-wh=2"] { min-width: 70px }
[data-d~="mn-wh=3"] { min-width: 40px }
[data-d~="mn-wh=4"] { min-width: 100px }
[data-d~="mn-wh=5"] { min-width: 80px }
[data-d~="mn-wh=6"] { min-width: 100% }

[data-d~="mx-wh=1"] { max-width: 80px }
[data-d~="mx-wh=2"] { max-width: 150px }
[data-d~="mx-wh=3"] { max-width: 100% }
[data-d~="mx-wh=4"] { max-width: 175px }
[data-d~="mx-wh=5"] { max-width: 25% }
[data-d~="mx-wh=6"] { max-width: 90% }
[data-d~="mx-wh=7"] { max-width: 140px }
[data-d~="mx-wh=8"] { max-width: 75% }
[data-d~="mx-wh=9"] { max-width: 300px }
[data-d~="mx-wh=10"] { max-width: 40px }
[data-d~="mx-wh=11"] { max-width: 110px }
[data-d~="mx-wh=12"] { max-width: 60px }
[data-d~="mx-wh=13"] { max-width: 90px }
[data-d~="mx-wh=14"] { max-width: 175px }
[data-d~="mx-wh=15"] { max-width: 50% }
[data-d~="mx-wh=16"] { max-width: 250px }
[data-d~="mx-wh=17"] { max-width: 170px }
[data-d~="mx-wh=18"] { max-width: 27px }
[data-d~="mx-wh=19"] { max-width: 200px }

[data-d~="ht=ao"] { height: auto }
[data-d~="ht=1"] { height: 100% }
[data-d~="ht=2"] { height: 40px }
[data-d~="ht=3"] { height: 5px }
[data-d~="ht=4"] { height: 50px }
[data-d~="ht=5"] { height: 60px }
[data-d~="ht=6"] { height: 10px }
[data-d~="ht=7"] { height: 80px }
[data-d~="ht=8"] { height: 600px }
[data-d~="ht=9"] { height: 400px }
[data-d~="ht=10"] { height: 175px }
[data-d~="ht=11"] { height: 100px }
[data-d~="ht=12"] { height: 500px }
[data-d~="ht=13"] { height: 275px }
[data-d~="ht=14"] { height: 150px }
[data-d~="ht=15"] { height: 70px }
[data-d~="ht=16"] { height: 20px }
[data-d~="ht=17"] { height: 0 }
[data-d~="ht=18"] { height: 1px }
[data-d~="ht=19"] { height: 30px }
[data-d~="ht=20"] { height: 4px }
[data-d~="ht=21"] { height: 300px }
[data-d~="ht=22"] { height: 120px }
[data-d~="ht=23"] { height: 16px }
[data-d~="ht=24"] { height: 2px }
[data-d~="ht=25"] { height: 26px }
[data-d~="ht=26"] { height: 80% }
[data-d~="ht=27"] { height: 200px }
[data-d~="ht=28"] { height: .5vw }
[data-d~="ht=29"] { height: 70% }

[data-d~="mn-ht=1"] { min-height: 140px }
[data-d~="mn-ht=2"] { min-height: 30px }
[data-d~="mn-ht=3"] { min-height: 80px }
[data-d~="mn-ht=4"] { min-height: 40px }
[data-d~="mn-ht=5"] { min-height: 400px }
[data-d~="mn-ht=6"] { min-height: 175px }
[data-d~="mn-ht=7"] { min-height: 70px }
[data-d~="mn-ht=8"] { min-height: 60px }
[data-d~="mn-ht=9"] { min-height: 50px }
[data-d~="mn-ht=10"] { min-height: 350px }
[data-d~="mn-ht=11"] { min-height: 100px }
[data-d~="mn-ht=12"] { min-height: 150px }

[data-d~="mx-ht=1"] { max-height: 80px }
[data-d~="mx-ht=2"] { max-height: 60px }
[data-d~="mx-ht=3"] { max-height: 100% }
[data-d~="mx-ht=4"] { max-height: 40% }
[data-d~="mx-ht=5"] { max-height: 80% }
[data-d~="mx-ht=6"] { max-height: 300px }
[data-d~="mx-ht=7"] { max-height: 90px }
[data-d~="mx-ht=8"] { max-height: 60% }





// [data-d~="ft-fy=0"] { font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" }

[data-d~="ft-fy=0"] { font-family: "roboto-regular" }

[data-d~="ft-fy=opensans-rr"] { font-family: "opensans-regular" }
[data-d~="ft-fy=opensans-eabd"] { font-family: "opensans-extrabold" }

[data-d~="ft-fy=test"] { font-family: serif }



[data-d~="ft-se=1"] { font-size: 12rem }
[data-d~="ft-se=2"] { font-size: 11rem }
[data-d~="ft-se=3"] { font-size: 14rem }
[data-d~="ft-se=4"] { font-size: 16rem }
[data-d~="ft-se=5"] { font-size: 18rem }
[data-d~="ft-se=6"] { font-size: 50rem }
[data-d~="ft-se=7"] { font-size: 24rem }
[data-d~="ft-se=8"] { font-size: 44rem }
[data-d~="ft-se=9"] { font-size: 30rem }
[data-d~="ft-se=10"] { font-size: 26rem }
[data-d~="ft-se=11"] { font-size: 20rem }
[data-d~="ft-se=12"] { font-size: 36rem }
[data-d~="ft-se=13"] { font-size: 19rem }
[data-d~="ft-se=14"] { font-size: 75rem }
[data-d~="ft-se=15"] { font-size: 100rem }
[data-d~="ft-se=16"] { font-size: 38rem }
[data-d~="ft-se=18"] { font-size: 22rem }
[data-d~="ft-se=19"] { font-size: 72rem }
[data-d~="ft-se=20"] { font-size: 9rem }
[data-d~="ft-se=21"] { font-size: 40rem }
[data-d~="ft-se=22"] { font-size: 48rem }
[data-d~="ft-se=23"] { font-size: 60rem }
[data-d~="ft-se=24"] { font-size: 10rem }

[data-d~="lr-sg=1"] { letter-spacing: 2rem }
[data-d~="lr-sg=2"] { letter-spacing: 1rem }
[data-d~="lr-sg=3"] { letter-spacing: 4rem }

[data-d~="le-ht=1"] { line-height: .8 }
[data-d~="le-ht=2"] { line-height: 2 }
[data-d~="le-ht=3"] { line-height: 14rem * 1.4 }
[data-d~="le-ht=4"] { line-height: 1.2 }
[data-d~="le-ht=5"] { line-height: 1 }
[data-d~="le-ht=6"] { line-height: .9 }
[data-d~="le-ht=7"] { line-height: 1.4 }
[data-d~="le-ht=8"] { line-height: 1.1 }
[data-d~="le-ht=9"] { line-height: 1.7 }
[data-d~="le-ht=10"] { line-height: 2.6 }

[data-d~="tt-an=cr"] { text-align: center }
[data-d~="tt-an=lt"] { text-align: left }

[data-d~="ft-wt=bd"] { font-weight: bold }

[data-d~="tt-dn=ne"] { text-decoration: none }
[data-d~="tt-dn=ue"] { text-decoration: underline }

[data-d~="tt-tm=ue"] { text-transform: uppercase }

[data-d~="wd-bk=bk-wd"] { word-break: break-word }

[data-d~="we-se=np"] { white-space: nowrap }

[data-d~="co=tr"] { color: #1DA1F2 }
[data-d~="co=fk"] { color: #1877F2 }
[data-d~="co=ye"] { color: #FF0000 }
[data-d~="co=im"] { color: #CD2E96 }
[data-d~="co=1"] { color: #000000 }
[data-d~="co=2"] { color: #FFFFFF }
[data-d~="co=3"] { color: #000000 }
[data-d~="co=4"] { color: #c01925 }
[data-d~="co=5"] { color: #898A9F }
[data-d~="co=6"] { color: #7c7c7c }
[data-d~="co=7"] { color: #808080 }
[data-d~="co=8"] { color: green }
[data-d~="co=9"] { color: #464646 }
[data-d~="co=10"] { color: #000000 }
[data-d~="co=11"] { color: #333333 }
[data-d~="co=12"] { color: rgba(255, 255, 255, .25) }
[data-d~="co=13"] { color: #cccccc }
[data-d~="co=14"] { color: #2b2f3b }
[data-d~="co=15"] { color: #c01925 }
[data-d~="co=16"] { color: #bababa }





[data-d~="pn=re"] { position: relative }
[data-d~="pn=ae"] { position: absolute }
[data-d~="pn=fd"] { position: fixed }
[data-d~="pn=sy"] { position: sticky }

[data-d~="tp=il"] { top: initial !important }
[data-d~="tp=1"] { top: 0 }
[data-d~="tp=2"] { top: 30px }
[data-d~="tp=3"] { top: -80px }
[data-d~="tp=4"] { top: 47.5px }
[data-d~="tp=5"] { top: 15% }
[data-d~="tp=6"] { top: 300px }
[data-d~="tp=7"] { top: 100% }
[data-d~="tp=8"] { top: 10px }
[data-d~="tp=9"] { top: -15px }
[data-d~="tp=10"] { top: 40px }
[data-d~="tp=11"] { top: -60px }
[data-d~="tp=12"] { top: -20px }
[data-d~="tp=13"] { top: -15px }
[data-d~="tp=14"] { top: 125% }
[data-d~="tp=15"] { top: 140px }
[data-d~="tp=16"] { top: 25% }
[data-d~="tp=18"] { top: 350px }
[data-d~="tp=19"] { top: 1px }
[data-d~="tp=20"] { top: 160px }
[data-d~="tp=21"] { top: 30% }

[data-d~="rt=1"] { right: 0 }
[data-d~="rt=2"] { right: 30px }
[data-d~="rt=3"] { right: 40px }
[data-d~="rt=4"] { right: (100 / (24 / 1)) * 1% }
[data-d~="rt=5"] { right: 35% }
[data-d~="rt=6"] { right: 100% }
[data-d~="rt=7"] { right: 10px }
[data-d~="rt=8"] { right: -250% }
[data-d~="rt=9"] { right: -15px }
[data-d~="rt=10"] { right: -70px }
[data-d~="rt=11"] { right: 47.5% }
[data-d~="rt=12"] { right: -5px }
[data-d~="rt=13"] { right: 100px }
[data-d~="rt=14"] { right: -580px }
[data-d~="rt=15"] { right: 1px }
[data-d~="rt=16"] { right: 12% }
[data-d~="rt=17"] { right: 20% }
[data-d~="rt=18"] { right: -30px }
[data-d~="rt=19"] { right: 10% }

[data-d~="bm=1"] { bottom: 0 }
[data-d~="bm=2"] { bottom: 30px }
[data-d~="bm=3"] { bottom: 60px }
[data-d~="bm=4"] { bottom: 10px }
[data-d~="bm=5"] { bottom: 35% }
[data-d~="bm=6"] { bottom: 17.5% }
[data-d~="bm=7"] { bottom: 32.5% }
[data-d~="bm=8"] { bottom: -5px }
[data-d~="bm=9"] { bottom: 40% }
[data-d~="bm=10"] { bottom: 55% }
[data-d~="bm=11"] { bottom: 20% }
[data-d~="bm=12"] { bottom: 1px }
[data-d~="bm=13"] { bottom: -20px }
[data-d~="bm=14"] { bottom: -60px }
[data-d~="bm=15"] { bottom: 200px }
[data-d~="bm=16"] { bottom: 120px }
[data-d~="bm=17"] { bottom: -30px }

[data-d~="lt=1"] { left: 0 }
[data-d~="lt=2"] { left: 30px }
[data-d~="lt=3"] { left: -100000px }
[data-d~="lt=4"] { left: -320px }
[data-d~="lt=5"] { left: 10px }
[data-d~="lt=6"] { left: -10px }
[data-d~="lt=7"] { left: 60px }
[data-d~="lt=8"] { left: 40% }
[data-d~="lt=9"] { left: 22.5% }
[data-d~="lt=10"] { left: 45% }
[data-d~="lt=11"] { left: 50% }
[data-d~="lt=12"] { left: 100px }
[data-d~="lt=13"] { left: 1px }
[data-d~="lt=14"] { left: 200px }
[data-d~="lt=15"] { left: 300px }
[data-d~="lt=16"] { left: -30px }





[data-d~="oy=1"] { opacity: 0 }
[data-d~="oy=2"] { opacity: .3 }
[data-d~="oy=3"] { opacity: .5 }

[data-d~="ot-ft=cr"] { object-fit: cover }

[data-d~="ot-pn=tp"] { object-position: top }
[data-d~="ot-pn=rt"] { object-position: right }
[data-d~="ot-pn=bm"] { object-position: bottom }
[data-d~="ot-pn=lt"] { object-position: left }
[data-d~="ot-pn=cr"] { object-position: center }

[data-d~="bx-sg=br-bx"] { box-sizing: border-box }

[data-d~="lt-se-te=ne"] { list-style-type: none }

[data-d~="dy=ne"] { display: none }
[data-d~="dy=bk"] { display: block }
[data-d~="dy=ie-bk"] { display: inline-block }

[data-d~="ow=hn"] { overflow: hidden }
[data-d~="ow-y=ao"] { overflow-y: auto }

[data-d~="br=ne"] { border: none }

[data-d~="oe=ne"] { outline: none }

[data-d~="ft=lt"] { float: left }

[data-d~="cu=dt"] { cursor: default }
[data-d~="cu=pr"] { cursor: pointer }

[data-d~="vy=hn"] { visibility: hidden }

[data-a~="fx-dn=cn"] { flex-direction: column }

[data-d~="cr=bh"] { clear: both }

[data-d~="wg-me=vl-rl"] { writing-mode: vertical-rl }

[data-d~="pr-es=ne"] { pointer-events: none }

[data-d~="ur-st=ne"] { user-select: none }





[data-d~="z-ix=1"] { z-index: 100 }
[data-d~="z-ix=2"] { z-index: 200 }
[data-d~="z-ix=3"] { z-index: 300 }
[data-d~="z-ix=4"] { z-index: 400 }
[data-d~="z-ix=5"] { z-index: 500 }
[data-d~="z-ix=6"] { z-index: 600 }
[data-d~="z-ix=7"] { z-index: 700 }
[data-d~="z-ix=8"] { z-index: 800 }
[data-d~="z-ix=9"] { z-index: 900 }
[data-d~="z-ix=11"] { z-index: 1000 }
[data-d~="z-ix=12"] { z-index: 2000 }
[data-d~="z-ix=13"] { z-index: 3000 }
[data-d~="z-ix=14"] { z-index: 4000 }
[data-d~="z-ix=15"] { z-index: 5000 }
[data-d~="z-ix=16"] { z-index: 6000 }
[data-d~="z-ix=17"] { z-index: 7000 }
[data-d~="z-ix=18"] { z-index: 8000 }
[data-d~="z-ix=19"] { z-index: 9000 }
[data-d~="z-ix=20"] { z-index: 10000 }





[data-d~="oe=1"] { outline: 20px solid #ffffff }





[data-d~="br-tp-wh=1"] { border-top-width: 20px }
[data-d~="br-tp-wh=2"] { border-top-width: 30px }

[data-d~="br-rt-wh=1"] { border-right-width: 10px }
[data-d~="br-rt-wh=2"] { border-right-width: 30px }

[data-d~="br-bm-wh=1"] { border-bottom-width: 15px }
[data-d~="br-bm-wh=2"] { border-bottom-width: 15px }
[data-d~="br-bm-wh=3"] { border-bottom-width: 20px }
[data-d~="br-bm-wh=4"] { border-bottom-width: 30px }

[data-d~="br-lt-wh=1"] { border-left-width: 15px }
[data-d~="br-lt-wh=2"] { border-left-width: 30px }



[data-d~="br-tp-se=1"] { border-top-style: solid }

[data-d~="br-rt-se=1"] { border-right-style: solid }

[data-d~="br-bm-se=1"] { border-bottom-style: solid }

[data-d~="br-lt-se=1"] { border-left-style: solid }



[data-d~="br-tp-cr=1"] { border-top-color: transparent }

[data-d~="br-rt-cr=1"] { border-right-color: transparent }
[data-d~="br-rt-cr=2"] { border-right-color: #171b24 }

[data-d~="br-bm-cr=1"] { border-bottom-color: #ffffff }
[data-d~="br-bm-cr=2"] { border-bottom-color: transparent }

[data-d~="br-lt-cr=1"] { border-left-color: transparent }
[data-d~="br-lt-cr=2"] { border-left-color: #000000 }
[data-d~="br-lt-cr=3"] { border-left-color: #e8e8e8 }
[data-d~="br-lt-cr=4"] { border-left-color: #f2f2f2 }
[data-d~="br-lt-cr=5"] { border-left-color: #2b2f3b }
[data-d~="br-lt-cr=6"] { border-left-color: #171b24 }
[data-d~="br-lt-cr=7"] { border-left-color: #ffffff }
[data-d~="br-lt-cr=8"] { border-left-color: #C01925 }



[data-d~="br-se=sd"] { border-style: solid }

[data-d~="br-tp-se=sd"] { border-top-style: solid }
[data-d~="br-rt-se=sd"] { border-right-style: solid }
[data-d~="br-bm-se=sd"] { border-bottom-style: solid }
[data-d~="br-lt-se=sd"] { border-left-style: solid }

[data-d~="br-bm-se=sd"] { border-bottom-style: solid }

[data-d~="br-rs=1"] { border-radius: 100% }
[data-d~="br-rs=2"] { border-radius: 0 }
[data-d~="br-rs=3"] { border-radius: 10px }

[data-d~="br-cr=1"] { border-color: #eb0000 }
[data-d~="br-cr=2"] { border-color: #EBEBEB }
[data-d~="br-cr=3"] { border-color: #FFFFFF }
[data-d~="br-cr=4"] { border-color: #222222 }
[data-d~="br-cr=5"] { border-color: #000000 }
[data-d~="br-cr=6"] { border-color: #e4e4e4 }
[data-d~="br-cr=7"] { border-color: #000000 }
[data-d~="br-cr=8"] { border-color: #d3d3d3 }
[data-d~="br-cr=9"] { border-color: rgba(0, 0, 0, .25) }
[data-d~="br-cr=10"] { border-color: #DA251C }
[data-d~="br-cr=11"] { border-color: #c9c9c9 }
[data-d~="br-cr=12"] { border-color: #e8e8e8 }
[data-d~="br-cr=13"] { border-color: #333333 }
[data-d~="br-cr=14"] { border-color: #444444 }

[data-d~="br-wh=1"] { border-width: 4px }
[data-d~="br-wh=2"] { border-width: 1px }
[data-d~="br-wh=3"] { border-width: 2px }
[data-d~="br-wh=4"] { border-width: 16px }
[data-d~="br-wh=5"] { border-width: 8px }





[data-d~="pg=1"] { padding: 20px }
[data-d~="pg=2"] { padding: 10px }
[data-d~="pg=3"] { padding: 5px 10px }
[data-d~="pg=4"] { padding: 10px 20px }
[data-d~="pg=5"] { padding: 5px }
[data-d~="pg=6"] { padding: 30px 50px }
[data-d~="pg=7"] { padding: 0 15px }
[data-d~="pg=8"] { padding: 10px 20px 10px 30px }
[data-d~="pg=9"] { padding: 0 10px }
[data-d~="pg=10"] { padding: 15px 40px 15px 20px }
[data-d~="pg=11"] { padding: 30px }
[data-d~="pg=12"] { padding: 60px 0 20px 0 }
[data-d~="pg=13"] { padding: 10px 80px }
[data-d~="pg=14"] { padding: 80px 40px 40px 40px }
[data-d~="pg=15"] { padding: 1px }
[data-d~="pg=16"] { padding: 2.5px 10px }
[data-d~="pg=17"] { padding: 15px 20px 10px 20px }
[data-d~="pg=18"] { padding: 20px 0 }
[data-d~="pg=19"] { padding: 40px }
[data-d~="pg=20"] { padding: 40px 0 }
[data-d~="pg=21"] { padding: 0 30px }
[data-d~="pg=22"] { padding: 30px 0 }
[data-d~="pg=23"] { padding: 10px 0 }
[data-d~="pg=24"] { padding: 5px 0 }
[data-d~="pg=25"] { padding: 12.5px 20px 12.5px 0 }
[data-d~="pg=26"] { padding: 20px 40px }
[data-d~="pg=27"] { padding: 60px 0 }
[data-d~="pg=28"] { padding: 0 5px }

[data-d~="pg-tp=1"] { padding-top: 10px }
[data-d~="pg-tp=2"] { padding-top: 30% }
[data-d~="pg-tp=3"] { padding-top: 60% }
[data-d~="pg-tp=4"] { padding-top: 90% }
[data-d~="pg-tp=5"] { padding-top: 10% }
[data-d~="pg-tp=6"] { padding-top: 100% }
[data-d~="pg-tp=7"] { padding-top: 12.5% }
[data-d~="pg-tp=8"] { padding-top: 50% }
[data-d~="pg-tp=9"] { padding-top: 55% }
[data-d~="pg-tp=10"] { padding-top: 30px }
[data-d~="pg-tp=11"] { padding-top: 2px }
[data-d~="pg-tp=12"] { padding-top: 35% }
[data-d~="pg-tp=13"] { padding-top: 20px }
[data-d~="pg-tp=14"] { padding-top: 35% }
[data-d~="pg-tp=15"] { padding-top: 25% }
[data-d~="pg-tp=16"] { padding-top: 20% }
[data-d~="pg-tp=17"] { padding-top: 60px }
[data-d~="pg-tp=18"] { padding-top: 75% }
[data-d~="pg-tp=19"] { padding-top: 100px }

[data-d~="pg-rt=il"] { padding-right: initial }
[data-d~="pg-rt=1"] { padding-right: 10px }
[data-d~="pg-rt=2"] { padding-right: 20px }
[data-d~="pg-rt=3"] { padding-right: 30px }
[data-d~="pg-rt=4"] { padding-right: 40px }
[data-d~="pg-rt=5"] { padding-right: 15px }

[data-d~="pg-bm=il"] { padding-bottom: initial }
[data-d~="pg-bm=1"] { padding-bottom: 10px }
[data-d~="pg-bm=2"] { padding-bottom: 60px }
[data-d~="pg-bm=3"] { padding-bottom: 10px }
[data-d~="pg-bm=4"] { padding-bottom: 100px }

[data-d~="pg-lt=il"] { padding-left: initial }
[data-d~="pg-lt=1"] { padding-left: 10px }
[data-d~="pg-lt=2"] { padding-left: 20px }
[data-d~="pg-lt=3"] { padding-left: 30px }
[data-d~="pg-lt=4"] { padding-left: 15px }

[data-d~="mn-tp=1"] { margin-top: 16px }
[data-d~="mn-tp=2"] { margin-top: 6px }
[data-d~="mn-tp=3"] { margin-top: 20px }
[data-d~="mn-tp=4"] { margin-top: 4px }
[data-d~="mn-tp=5"] { margin-top: 2px }
[data-d~="mn-tp=6"] { margin-top: 40px }
[data-d~="mn-tp=7"] { margin-top: 60px }
[data-d~="mn-tp=8"] { margin-top: 100px }
[data-d~="mn-tp=9"] { margin-top: 10px }
[data-d~="mn-tp=10"] { margin-top: 3px }
[data-d~="mn-tp=11"] { margin-top: 1px }
[data-d~="mn-tp=12"] { margin-top: 24px }
[data-d~="mn-tp=13"] { margin-top: 5px }
[data-d~="mn-tp=14"] { margin-top: 30px }
[data-d~="mn-tp=15"] { margin-top: 80px }
[data-d~="mn-tp=16"] { margin-top: -100px }

[data-d~="mn-rt=1"] { margin-right: 40px }
[data-d~="mn-rt=2"] { margin-right: 6px }
[data-d~="mn-rt=3"] { margin-right: 20px }
[data-d~="mn-rt=4"] { margin-right: 10px }
[data-d~="mn-rt=5"] { margin-right: 2px }

[data-d~="mn-bm=1"] { margin-bottom: 20px }
[data-d~="mn-bm=2"] { margin-bottom: 40px }
[data-d~="mn-bm=3"] { margin-bottom: 10px }

[data-d~="mn-lt=1"] { margin-left: 20px }
[data-d~="mn-lt=2"] { margin-left: 10px }
[data-d~="mn-lt=3"] { margin-left: 4px }
[data-d~="mn-lt=4"] { margin-left: 50px }
[data-d~="mn-lt=5"] { margin-left: 30px }
[data-d~="mn-lt=6"] { margin-left: 16px }
[data-d~="mn-lt=7"] { margin-left: 40px }
[data-d~="mn-lt=8"] { margin-left: 6px }
[data-d~="mn-lt=9"] { margin-left: 2px }
[data-d~="mn-lt=10"] { margin-left: 100px }

[data-d~="bx-sw=1"] { box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, .125) }
[data-d~="bx-sw=2"] { box-shadow: inset 0px 150px 250px -150px rgba(0, 0, 0, .25) } 



[data-d~="tn=1"] { transition: all .8s cubic-bezier(.44, .15, .2, 1) }

[data-d~="tn-dn=1"] { transition-duration: 250ms }
[data-d~="tn-dn=2"] { transition-duration: 500ms }

[data-d~="tn-dy=1"] { transition-delay: 10ms }
[data-d~="tn-dy=2"] { transition-delay: 20ms }
[data-d~="tn-dy=3"] { transition-delay: 30ms }
[data-d~="tn-dy=4"] { transition-delay: 40ms }
[data-d~="tn-dy=5"] { transition-delay: 50ms }
[data-d~="tn-dy=6"] { transition-delay: 60ms }
[data-d~="tn-dy=7"] { transition-delay: 70ms }
[data-d~="tn-dy=8"] { transition-delay: 80ms }
[data-d~="tn-dy=9"] { transition-delay: 90ms }
[data-d~="tn-dy=10"] { transition-delay: 100ms }

[data-d~="tm=1"] { transform: translateX(-100vw) }
[data-d~="tm=2"] { transform: rotate(45deg) }
[data-d~="tm=3"] { transform: rotate(90deg) }
[data-d~="tm=4"] { transform: rotate(180deg) }
[data-d~="tm=5"] { transform: translateY(-30px) }
[data-d~="tm=6"] { transform: translateY(20px) }
[data-d~="tm=7"] { transform: translateX(-50px) }



[data-d~="an-in-ct=1"] { animation-iteration-count: infinite }
[data-d~="an-in-ct=ie"] { animation-iteration-count: infinite }

[data-d~="an-tg-fn=ee-in-ot"] { animation-timing-function: ease-in-out }

[data-d~="an-fl-me=fs"] { animation-fill-mode: forwards }

[data-d~="an-py-se=pd"] { animation-play-state: paused }

[data-d~="an-dn=1"] { animation-duration: 2000ms }
[data-d~="an-dn=2"] { animation-duration: 6000ms }

[data-d~="an-dy=1"] { animation-delay: 1000ms }

[data-d~="an-ne=1"] { animation-name: pulse_1__1 }
[data-d~="an-ne=2"] { animation-name: levitation_1__1 }
[data-d~="an-ne=3"] { animation-name: pulse_2__1 }
[data-d~="an-ne=4"] { animation-name: pulse_3__1 }
[data-d~="an-ne=5"] { animation-name: preloader_2__1 }













[data-d~="!=co=1"] { color: #000000 !important }

[data-d~="!=lr-sg=1"] { letter-spacing: initial !important }












[data-d~="%=bd-cr=1"]:hover { background-color: #2b2f3b }

[data-d~="%=co=1"]:hover { color: #000000 }
[data-d~="%=co=2"]:hover { color: #000000 }
[data-d~="%=co=3"]:hover { color: #ffffff }
[data-d~="%=co=4"]:hover { color: #000000 }

[data-d~="%=br-cr=1"]:hover { border-color: #eb0000 }
[data-d~="%=br-cr=2"]:hover { border-color: #000000 }

[data-d~="%=tt-dn=ue"]:hover { text-decoration: underline }

[data-d~="%=bx-sw=1"]:hover { box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, .125) }

[data-d~="%=tm=1"]:hover { transform: scale(1.1) }





[data-d~="%=wh=1__1"] {
	&:hover {
		[data-d~="%=wh=1__2"] {
			width: 100%;
		}
	}
}

[data-d~="%=bd-cr=1__1"] {
	&:hover {
		[data-d~="%=bd-cr=1__2"] {
			background-color: #ffffff;
		}
	}
}

[data-d~="%=co=1__1"] {
	&:hover {
		[data-d~="%=co=1__2"] {
			color: #ffffff;
		}
		[data-d~="%=co=1__3"] {
			color: #000000;
		}
		[data-d~="%=co=1__4"] {
			color: #000000;
		}
		[data-d~="%=co=1__5"] {
			color: #000000;
		}
	}
}

[data-d~="%=tt-dn=1__1"] {
	&:hover {
		[data-d~="%=tt-dn=1__2"] {
			text-decoration: underline;
		}
	}
}

[data-d~="%=tm=1__1"] {
	&:hover {
		[data-d~="%=tm=1__2"] {
			transform: scale(1.1);
		}
	}
}

[data-d~="%=oy=1__1"] {
	&:hover,
	&:focus-within {
		[data-d~="%=oy=1__2"] {
			opacity: 1
		}
	}
}

[data-d~="%=lt=1__1"] {
	&:hover {
		[data-d~="%=lt=1__2"] {
			left: 0
		}
	}
}

[data-d~="%=tm=2__1"] {
	&:hover,
	&:focus-within {
		[data-d~="%=tm=2__2"] {
			transform: translateY(0)
		}
	}
}

[data-d~="%=pr-es=1__1"] {
	&:hover,
	&:focus-within {
		[data-d~="%=pr-es=1__2"] {
			pointer-events: auto
		}
	}
}





[data-d~="%=1__1"] {
	& {
		> li {
			&:first-child { margin-left: initial }
			&:last-child { display: none }
		}
	}
}

[data-d~="%=2__1"] {
	& {
		> span {
			background-color: transparent;
			width: 12px;
			height: 12px;
			border-style: solid;
			border-color: #000000;
			border-width: 2px;
			opacity: 1;
			margin-left: 4px;
			display: flex;
			justify-content: center;
			justify-content: center;
			align-items: center;

			&:first-child {
				margin-left: initial;
			}
			&.swiper-pagination-bullet-active::before {
				content: "";
				background-color: #000000;
				width: 8px;
				height: 8px;
				border-radius: 100%;
			}
		}
	}
}

[data-d~="%=2__2"] {
	& {
		color: #ffffff;
		font-size: 20px;

		span {
			background-color: #000000;
			color: #ffffff;
			padding: 8px 10px 10px 10px;
			border-radius: 10px;
			line-height: 1;

			&:first-child {
				margin-right: 10px;
			}
			&:last-child {
				margin-left: 10px;
			}
		}
	}
}

[data-d~="%=2__3"] {
	& {
		> span {
			background-color: transparent;
			width: 12px;
			height: 12px;
			border-style: solid;
			border-color: #ffffff;
			border-width: 2px;
			opacity: 1;
			margin-left: 4px;
			display: flex;
			justify-content: center;
			justify-content: center;
			align-items: center;

			&:first-child {
				margin-left: initial;
			}
			&.swiper-pagination-bullet-active::before {
				content: "";
				background-color: #ffffff;
				width: 8px;
				height: 8px;
				border-radius: 100%;
			}
		}
	}
}

[data-d~="%=3__1"] {
	&:nth-child(odd) { padding-right: 40px }
	&:nth-child(even) { padding-left: 40px }
	&:nth-child(-n+2) { padding-top: initial }
	&:nth-last-child(2) {
		> div {
			border: none;
			padding-bottom: initial;
		}
	}
	&:last-child {
		> div {
			border: none;
			padding-bottom: initial;
		}
	}
}

[data-d~="%=4__1"] {
	&:last-child {
		margin-bottom: 20px;
	}
}

[data-d~="%=5__1"] {
	& {
		&:first-child {
			margin-top: initial
		}
	}
}

[data-d~="%=6__1"] {
	& {
		img {
			max-width: 100%;
		}
		iframe {
			max-width: 100%;
		}
		ul {
			list-style-position: inside;
			margin-top: 20px;
		}
		ol {
			list-style-position: inside;
			margin-top: 20px;
		}
		p {
			margin-top: 20px;
		}
		a {
			color: #DA251C;

			&:hover {
				text-decoration: underline !important;
			}
		}
		table {
			margin-top: 20px;
			padding: unset;

			td {
				padding: 20px;
			}
		}
		h1 {
			font-size: 26rem;
			font-weight: bold;
			line-height: 1;
			margin-top: 20px;
		}
		h2 {
			font-size: 24rem;
			font-weight: bold;
			line-height: 1;
			margin-top: 20px;
		}
		h3 {
			font-size: 22rem;
			font-weight: bold;
			line-height: 1;
			margin-top: 20px;
		}
		h4 {
			font-size: 20rem;
			font-weight: bold;
			line-height: 1;
			margin-top: 20px;
		}
		h5 {
			font-size: 18rem;
			font-weight: bold;
			line-height: 1;
			margin-top: 20px;
		}
		h6 {
			font-size: 16rem;
			font-weight: bold;
			line-height: 1;
			margin-top: 20px;
		}
	}
}

[data-d~="%=7__1"] {
	& {
		&:last-child {
			border: initial
		}
	}
}

[data-d~="%=8__1"] {
	&:focus {
		left: 0;
		z-index: 99999;
	}
}

[data-d~="%=9__1"] {
	& {
		border: #000000 solid 1px;
		border-collapse: collapse;

		tr {
			td {
				border: #000000 solid 1px;
				border-collapse: collapse;
				padding: 5px;
			}
		}
	}
}

[data-d~="%=10__1"] {
	& {
		a {
			color: #000000;

			&:hover {
				text-decoration: underline;
			}
		}
		table {
			width: 100%;
		}
		h1, h2, h3, h4, h5, h6 {
			font-size: 14px;
			margin-bottom: 10px;
		}
	}
}

[data-d~="%=11__1"] {
	&:nth-child(-n+3) {
		[data-d~="%=11__2"] {
			left: 0;
			right: initial;
		}
	}
}

[data-d~="%=12__1"] {
	&:last-child {
		[data-d~="%=12__2"] {
			border: initial
		}
	}
}

[data-d~="%=13__1"] {
	&:hover {
		~ [data-d~="%=13__2"] {

			[data-d~="%=13__3"] {
				background-color: #000000;
			}
			[data-d~="%=13__4"] {
				display: flex;
			}
			[data-d~="%=13__5"] {
				background-color: #000000;
			}
		}
	}
	&:checked {
		~ [data-d~="%=13__2"] {

			[data-d~="%=13__3"] {
				background-color: #000000;
			}
			[data-d~="%=13__4"] {
				display: flex;
			}
			[data-d~="%=13__5"] {
				background-color: #000000;
			}
		}
	}
}

[data-d~="%=14__1"] {
	&.not-empty,
	&:focus {
		~ [data-d~="%=14__2"] {
			bottom: 100%;

			[data-d~="%=14__3"] {
				font-size: 14px;
			}
		}
	}
}

[data-d~="%=15__1"] {
	&:nth-child(odd) {
		background-color: #F2F2F2;

		[data-d~="%=15__2"] {
			border-left-color: #F2F2F2;
		}
	}
	&:nth-child(even) {
		background-color: #E8E8E8;

		[data-d~="%=15__2"] {
			border-left-color: #E8E8E8;
		}
	}
}

[data-d~="%=16__1"] {
    & {
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    &::-webkit-scrollbar {
        opacity: 0;
    }
}

[data-d~="%=17__1"] {
	&::placeholder {
		color: #ffffff
	}
}

[data-d~="%=18__1"] {
	width: initial !important;
	background: #6a6a6a !important;
	height: 2px !important;
	right: 10px !important;
	left: 10px !important;
	box-sizing: border-box;

	span {
		background: white !important;
	}
}











/* xxl */
@media (max-width: 1800px) {}
@media (max-width: 1700px) {
	[data-d~="@1700=dy=ne"] { display: none }
}
@media (max-width: 1600px) {
	[data-d~="@1600=wh=1"] { width: 60px }
	[data-d~="@1600=wh=2"] { width: 100% }

	[data-d~="@1600=mx-wh=1"] { max-width: 200px }

	[data-d~="@1600=ht=1"] { height: 30px }
	[data-d~="@1600=ht=2"] { height: 10px }
	[data-d~="@1600=ht=3"] { height: 5px }
	[data-d~="@1600=ht=4"] { height: 20px }
	[data-d~="@1600=ht=5"] { height: 60px }
	[data-d~="@1600=ht=6"] { height: 40px }

	[data-d~="@1600=mn-ht=1"] { min-height: 40px }

	[data-d~="@1600=ft-se=1"] { font-size: 30rem }

	[data-d~="@1600=dy=ne"] { display: none }

	[data-d~="@1600=br-wh=1"] { border-width: 10px }

	[data-d~="@1600=pg=1"] { padding: 10px 40px }

	[data-d~="@1600=pg-tp=1"] { padding-top: 30px }
	[data-d~="@1600=pg-tp=2"] { padding-top: 30% }
	[data-d~="@1600=pg-tp=3"] { padding-top: 20% }
	[data-d~="@1600=pg-tp=4"] { padding-top: 30px }

	[data-d~="@1600=pg-bm=1"] { padding-bottom: 30px }

	[data-d~="@1600=mn-tp=1"] { margin-top: 10px }
}
/* xl */
@media (max-width: 1500px) {
	[data-d~="@xl=wh=1"] { width: 100% }
	[data-d~="@xl=wh=2"] { width: 80px }

	[data-d~="@xl=ht=1"] { height: 80px }

	[data-d~="@xl=ft-se=1"] { font-size: 18rem }
	[data-d~="@xl=ft-se=2"] { font-size: 30rem }
	[data-d~="@xl=ft-se=3"] { font-size: 16rem }
	[data-d~="@xl=ft-se=4"] { font-size: 12rem }
	[data-d~="@xl=ft-se=5"] { font-size: 24rem }

	[data-d~="@xl=dy=ne"] { display: none }

	[data-d~="@xl=or=1"] { order: 1 }
	[data-d~="@xl=or=2"] { order: 2 }
	[data-d~="@xl=or=3"] { order: 3 }

	[data-d~="@xl=pg-tp=1"] { padding-top: 90% }
	[data-d~="@xl=pg-tp=2"] { padding-top: 40% }

	[data-d~="@xl=mn-tp=1"] { margin-top: 40px }
	[data-d~="@xl=mn-tp=2"] { margin-top: 20px }

	[data-d~="@xl=mn-bm=1"] { margin-bottom: 60px }

	[data-d~="@xl=mn-lt=il"] { margin-left: initial }
}
@media (max-width: 1400px) {
	[data-d~="@1400=mx-wh=1"] { max-width: 55px }
	[data-d~="@1400=mx-wh=2"] { max-width: 150px }

	[data-d~="@1400=ht=1"] { height: 20px }

	[data-d~="@1400=mn-ht=1"] { min-height: initial }

	[data-d~="@1400=ft-se=1"] { font-size: 30rem }
	[data-d~="@1400=ft-se=2"] { font-size: 50rem }
	[data-d~="@1400=ft-se=3"] { font-size: 20rem }

	[data-d~="@1400=lt=1"] { left: 50px }

	[data-d~="@1400=pg-tp=1"] { padding-top: 45% }

	[data-d~="@1400=pg-bm=1"] { padding-bottom: 150px }

	[data-d~="@1400=mn-tp=1"] { margin-top: 10px }

	[data-d~="@1400=mn-lt=1"] { margin-left: 60px }
}
@media (max-width: 1300px) {
	[data-d~="@1300=ht=il"] { height: initial }

	[data-d~="@1300=pg=1"] { padding: 40px }

	[data-d~="@1300=pg-tp=1"] { padding-top: 60% }

	[data-d~="@1300=mn-bm=1"] { margin-bottom: 16px }
}
/* l */
@media (max-width: 1200px) {
	[data-d~="@l=wh=1"] { width: 40px }
	[data-d~="@l=wh=2"] { width: 46px }
	[data-d~="@l=wh=3"] { width: 100% }
	[data-d~="@l=wh=4"] { width: calc(100% - 96px) }
	[data-d~="@l=wh=5"] { width: 50px }
	[data-d~="@l=wh=6"] { width: 200px }
	[data-d~="@l=wh=7"] { width: 100% }

	[data-d~="@l=mx-wh=1"] { max-width: 60px }

	[data-d~="@l=ht=1"] { height: 40px }
	[data-d~="@l=ht=2"] { height: 46px }
	[data-d~="@l=ht=3"] { height: 50px }
	[data-d~="@l=ht=4"] { height: 20px }

	[data-d~="@l=mn-ht=1"] { min-height: 40px }
	[data-d~="@l=mn-ht=2"] { min-height: 400px }
	[data-d~="@l=mn-ht=3"] { min-height: 20px }

	[data-d~="@l=ft-se=1"] { font-size: 18rem }
	[data-d~="@l=ft-se=2"] { font-size: 30rem }
	[data-d~="@l=ft-se=3"] { font-size: 24rem }
	[data-d~="@l=ft-se=4"] { font-size: 50rem }
	[data-d~="@l=ft-se=5"] { font-size: 40rem }

	[data-d~="@l=dy=ne"] { display: none }
	[data-d~="@l=dy=bk"] { display: block }
	[data-d~="@l=dy=fx"] { display: flex }

	[data-d~="@l=fx-wp=wp"] { flex-wrap: wrap }

	[data-d~="@l=jy-ct=il"] { justify-content: initial }

	[data-d~="@l=or=1"] { order: 1 }
	[data-d~="@l=or=2"] { order: 2 }
	[data-d~="@l=or=3"] { order: 3 }

	[data-d~="@l=br-se=sd"] { border-style: solid }

	[data-d~="@l=br-bm-se=sd"] { border-bottom-style: solid }

	[data-d~="@l=br-cr=1"] { border-color: #2941A7 }
	[data-d~="@l=br-cr=2"] { border-color: #F4F4F4 }
	[data-d~="@l=br-cr=3"] { border-color: #ffffff }

	[data-d~="@l=br-wh=1"] { border-width: 1px }

	[data-d~="@l=pg-tp=1"] { padding-top: 40% }
	[data-d~="@l=pg-tp=2"] { padding-top: 30% }
	[data-d~="@l=pg-tp=3"] { padding-top: 50% }

	[data-d~="@l=pg-rt=il"] { padding-right: initial }

	[data-d~="@l=pg-lt=il"] { padding-left: initial }

	[data-d~="@l=mn-tp=1"] { margin-top: 20px }
	[data-d~="@l=mn-tp=2"] { margin-top: 16px }
	[data-d~="@l=mn-tp=3"] { margin-top: 10px }
	[data-d~="@l=mn-tp=4"] { margin-top: 40px }

	[data-d~="@l=mn-bm=1"] { margin-bottom: 20px }

	[data-d~="@l=mn-lt=il"] { margin-left: initial }
	[data-d~="@l=mn-lt=1"] { margin-left: 10px }
	[data-d~="@l=mn-lt=2"] { margin-left: 20px }
	[data-d~="@l=mn-lt=3"] { margin-left: 5px }
}
@media (max-width: 1000px) {
	[data-d~="@1000=wh=1"] { width: 100% }

	[data-d~="@1000=ht=1"] { height: 10px }
	[data-d~="@1000=ht=2"] { height: 30px }

	[data-d~="@1000=ft-se=1"] { font-size: 40rem }
	[data-d~="@1000=ft-se=2"] { font-size: 30rem }

	[data-d~="@1000=le-ht=1"] { line-height: .8 }

	[data-d~="@1000=pn=re"] { position: relative }
	[data-d~="@1000=pn=il"] { position: initial }

	[data-d~="@1000=dy=ne"] { display: none }
	[data-d~="@1000=dy=bk"] { display: block }

	[data-d~="@1000=pg-bm=1"] { padding-bottom: initial }
}
/* m */
@media (max-width: 900px) {
	[data-d~="@m=wh=il"] { width: initial }
	[data-d~="@m=wh=1"] { width: 60px }
	[data-d~="@m=wh=2"] { width: 100% }
	[data-d~="@m=wh=3"] { width: 90% }
	[data-d~="@m=wh=4"] { width: 46px }
	[data-d~="@m=wh=5"] { width: 90px }
	[data-d~="@m=wh=6"] { width: 60% }

	[data-d~="@m=mn-wh=1"] { min-width: 200px }

	[data-d~="@m=mx-wh=1"] { max-width: 50px }
	[data-d~="@m=mx-wh=2"] { max-width: 100px }
	[data-d~="@m=mx-wh=3"] { max-width: 175px }

	[data-d~="@m=ht=il"] { height: initial }
	[data-d~="@m=ht=1"] { height: 60px }
	[data-d~="@m=ht=2"] { height: 30px }
	[data-d~="@m=ht=3"] { height: 15px }
	[data-d~="@m=ht=4"] { height: 20px }
	[data-d~="@m=ht=5"] { height: 46px }
	[data-d~="@m=ht=6"] { height: 200px }
	[data-d~="@m=ht=7"] { height: 5px }

	[data-d~="@m=mn-ht=1"] { min-height: 40px }
	[data-d~="@m=mn-ht=2"] { min-height: 50px }

	[data-d~="@m=pn=il"] { position: initial }
	[data-d~="@m=pn=ae"] { position: absolute }
	[data-d~="@m=pn=sy"] { position: sticky }

	[data-d~="@m=tp=1"] { top: 150px }

	[data-d~="@m=rt=il"] { right: initial }
	[data-d~="@m=rt=1"] { right: 20px }
	[data-d~="@m=rt=2"] { right: 0 }
	[data-d~="@m=rt=3"] { right: 40px }

	[data-d~="@m=bm=il"] { bottom: initial }
	[data-d~="@m=bm=1"] { bottom: 70px }
	[data-d~="@m=bm=2"] { bottom: 40px }
	[data-d~="@m=bm=3"] { bottom: 20px }

	[data-d~="@m=lt=il"] { left: initial }

	[data-d~="@m=ft-se=1"] { font-size: 26rem }
	[data-d~="@m=ft-se=2"] { font-size: 20rem }
	[data-d~="@m=ft-se=3"] { font-size: 14rem }
	[data-d~="@m=ft-se=4"] { font-size: 22rem }
	[data-d~="@m=ft-se=5"] { font-size: 11rem }
	[data-d~="@m=ft-se=6"] { font-size: 18rem }
	[data-d~="@m=ft-se=7"] { font-size: 40rem }
	[data-d~="@m=ft-se=8"] { font-size: 24rem }
	[data-d~="@m=ft-se=9"] { font-size: 60rem }
	[data-d~="@m=ft-se=10"] { font-size: 30rem }
	[data-d~="@m=ft-se=11"] { font-size: 50rem }

	[data-d~="@m=dy=ne"] { display: none }
	[data-d~="@m=dy=bk"] { display: block }
	[data-d~="@m=dy=fx"] { display: flex }

	[data-d~="@m=jy-ct=se-bn"] { justify-content: space-between }
	[data-d~="@m=fx-wp=wp"] { flex-wrap: wrap }

	[data-d~="@m=br-wh=1"] { border-width: 8px }

	[data-d~="@m=pg=1"] { padding: 10px }
	[data-d~="@m=pg=2"] { padding: 10px 10px 7.5px 10px }
	[data-d~="@m=pg=3"] { padding: 0 40px }
	[data-d~="@m=pg=4"] { padding: 40px 0 }

	[data-d~="@m=pg-tp=il"] { padding-top: initial }
	[data-d~="@m=pg-tp=1"] { padding-top: 55% }
	[data-d~="@m=pg-tp=2"] { padding-top: 50% }
	[data-d~="@m=pg-tp=3"] { padding-top: 30% }
	[data-d~="@m=pg-tp=4"] { padding-top: 100% }
	[data-d~="@m=pg-tp=5"] { padding-top: 60% }

	[data-d~="@m=pg-rt=il"] { padding-right: initial }

	[data-d~="@m=pg-lt=il"] { padding-left: initial }

	[data-d~="@m=mn-tp=il"] { margin-top: initial }
	[data-d~="@m=mn-tp=1"] { margin-top: 16px }
	[data-d~="@m=mn-tp=2"] { margin-top: 20px }
	[data-d~="@m=mn-tp=3"] { margin-top: 40px }
	[data-d~="@m=mn-tp=4"] { margin-top: 2px }
	[data-d~="@m=mn-tp=5"] { margin-top: 10px }
	[data-d~="@m=mn-tp=6"] { margin-top: 6px }
	[data-d~="@m=mn-tp=7"] { margin-top: 4px }

	[data-d~="@m=mn-bm=1"] { margin-bottom: 16px }
	[data-d~="@m=mn-bm=2"] { margin-bottom: 20px }
	[data-d~="@m=mn-bm=3"] { margin-bottom: 10px }

	[data-d~="@m=mn-lt=il"] { margin-left: initial }
	[data-d~="@m=mn-lt=1"] { margin-left: 20px }
	[data-d~="@m=mn-lt=2"] { margin-left: 10px }
	[data-d~="@m=mn-lt=3"] { margin-left: 40px }
	[data-d~="@m=mn-lt=4"] { margin-left: 6px }
	[data-d~="@m=mn-lt=5"] { margin-left: 4px }

	[data-d~="@m=tm=il"] { transform: initial }

	[data-d~="@m=%=1__1"] {
		&:last-child { display: none }
	}

	[data-d~="@m=%=3__1"] {
		&:nth-child(odd) { padding-right: initial }
		&:nth-child(even) { padding-left: initial }
		&:nth-child(1) { padding-top: initial }
		&:nth-child(2) { padding-top: 10px }
		&:nth-last-child(2) {
			> div {
				border-bottom-style: solid;
				border-color: rgba(0, 0, 0, .25);
				border-width: 1px;
				padding-bottom: 10px;
			}
		}
	}
	[data-d~="@m=%=3__2"] {
		&:nth-last-child(2) {
			> div {
				border-bottom-style: solid;
				border-color: rgba(0, 0, 0, .25);
				border-width: 1px;
				padding-bottom: 10px;
			}
		}
	}
}
@media (max-width: 800px) {
	[data-d~="@800=ft-se=1"] { font-size: 14rem }
	[data-d~="@800=ft-se=2"] { font-size: 22rem }

	[data-d~="@800=tp=1"] { top: 40px }

	[data-d~="@800=rt=1"] { right: 20px }
}
@media (max-width: 700px) {
	[data-d~="@700=pg=1"] { padding: 80px 40px 0 40px }
	[data-d~="@700=pg-bm=il"] { padding-bottom: initial }

	[data-d~="@700=mn-lt=il"] { margin-left: initial }
	[data-d~="@700=mn-tp=1"] { margin-top: 10px }
	[data-d~="@700=mn-tp=2"] { margin-top: 20px }
}
/* s */
@media (max-width: 600px) {
	[data-d~="@s=wh=ut"] { width: unset }
	[data-d~="@s=wh=1"] { width: calc(100% - 140px) }
	[data-d~="@s=wh=2"] { width: calc(100% - 70px) }
	[data-d~="@s=wh=3"] { width: 40px }

	[data-d~="@s=mx-wh=1"] { max-width: 50% }
	[data-d~="@s=mx-wh=2"] { max-width: 100px }
	[data-d~="@s=mx-wh=3"] { max-width: 125px }

	[data-d~="@s=ht=1"] { height: 40px }

	[data-d~="@s=ft-se=1"] { font-size: 30rem }
	[data-d~="@s=ft-se=2"] { font-size: 75rem }
	[data-d~="@s=ft-se=3"] { font-size: 20rem }
	[data-d~="@s=ft-se=4"] { font-size: 24rem }
	[data-d~="@s=ft-se=5"] { font-size: 16rem }

	[data-d~="@s=or=1"] { order: 1 }
	[data-d~="@s=or=2"] { order: 2 }
	[data-d~="@s=or=3"] { order: 3 }

	[data-d~="@s=pn=fd"] { position: fixed }

	[data-d~="@s=tp=1"] { top: 20px }

	[data-d~="@s=rt=1"] { right: 20px }

	[data-d~="@s=lt=1"] { left: 20px }

	[data-d~="@s=dy=fx"] { display: flex }
	[data-d~="@s=dy=il"] { display: initial }
	[data-d~="@s=jy-ct=cr"] { justify-content: center }
	[data-d~="@s=fx-wp=wp"] { flex-wrap: wrap }

	[data-d~="@s=pg=1"] { padding: 10px }

	[data-d~="@s=pg-tp=1"] { padding-top: 60% }
	[data-d~="@s=pg-tp=2"] { padding-top: 55% }
	[data-d~="@s=pg-tp=3"] { padding-top: 75% }
	[data-d~="@s=pg-tp=4"] { padding-top: 100% }

	[data-d~="@s=mn-tp=il"] { margin-top: initial }
	[data-d~="@s=mn-tp=1"] { margin-top: 10px }
	[data-d~="@s=mn-tp=2"] { margin-top: 20px }
	[data-d~="@s=mn-tp=3"] { margin-top: 40px }
	[data-d~="@s=mn-tp=4"] { margin-top: 15px }
	[data-d~="@s=mn-tp=5"] { margin-top: 5px }

	[data-d~="@s=mn-bm=1"] { margin-bottom: 10px }

	[data-d~="@s=mn-lt=il"] { margin-left: initial }
	[data-d~="@s=mn-lt=1"] { margin-left: 20px }
	[data-d~="@s=mn-lt=2"] { margin-left: 10px }
}
@media (max-width: 400px) {
	[data-d~="@400=pg-tp=1"] { padding-top: 90% }
	[data-d~="@400=pg-tp=2"] { padding-top: 70% }

	[data-d~="@400=lt=1"] { left: 20px }
}
/* xs */
@media (max-width: 300px) {}
